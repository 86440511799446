.kontakt {
    width: 100%;
    color: black;
    margin: 60px auto;
    display: flex;
    justify-content: space-evenly;
}

.kontakt .form {
    width: 30%;
    margin: auto;
}

.kontakt .center_vertical_line {
    width: 2px;
    background-color: rgb(21, 78, 21);
    box-shadow: 3px 3px 6px rgba(0,0,0,.25), -3px 0px 6px rgba(0,0,0,.25);
}

.left {
    width: 30%;
    margin: auto;
}

.kontakt h1 {
    margin: 0;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
    color: rgb(37, 82, 37);
}

.kontakt h2 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 26px;
}

.inputBox {
    position: relative;
    width: 100%;
    margin: 0 20px 10px 0;
    padding: 0;
    display: block;
}

.inputBox select:hover {
    cursor: pointer;
}

.inputBox textarea {
    resize: vertical;
    min-height: 100px;
    font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 1em;
}

.form_input, .form_select {
    width: 100%;
    padding: 10px 0 10px 10px;
    border: 1px solid rgb(21, 78, 21);
    background: transparent;
    outline: none;
    font-size: 1em;
    border-radius: 5px;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
}

.form_label {
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 8px;
    cursor: text;
    pointer-events: none;
}

.form_input:focus ~ .form_label,
.form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
    top: -8px;
    left: 8px;
    font-size: 0.7em;
    text-transform: uppercase;
    background: rgb(21, 78, 21);
    padding: 0 4px;
    bottom: auto;
    color: white;
    border-radius: 4px;
    transition: 0.5s;
}

.form_select:focus ~ .form_label,
.form_select:valid ~ .form_label {
    top: -8px;
    left: 8px;
    font-size: 0.7em;
    text-transform: uppercase;
    background: rgb(21, 78, 21);
    padding: 0 4px;
    bottom: auto;
    color: white;
    border-radius: 3px;
    transition: 0.5s;
}

.inputBox:nth-child(2) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(3) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(4) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(5) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(6) {
    display: block;
    width: calc(80% + 30px);
}

.inputBox:nth-child(7) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(8) {
    display: inline-block;
    width: 40%;
}

.inputBox:nth-child(9) {
    display: block;
    width: calc(80% + 20px);
}

.inputBox:nth-child(10) {
    display: block;
    width: 40%;
}

.inputBox:nth-child(11) {
    display: block;
    width: calc(80% + 20px);
}

.form button {
    border: 1px solid rgb(21, 78, 21);
    border-radius: 40px;
    background-image: linear-gradient(90deg, rgb(49, 165, 49), rgb(37, 82, 37));
    color: white;
    width: 100px;
    height: 40px;
    font-weight: bold;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
    transition: 0.5s;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.form button:hover {
    color: white;
    cursor: pointer;
    opacity: 0.9;
    font-size: 1em;
}

.contact_logo {
    width: fit-content;
    margin: auto;
}

.contact_logo img {
    width: 100%;
}

.kontakt .horizontal_line {
    display: none;
}

@media (max-width: 1100px) {
    .kontakt {
        display: grid;
    }

    .kontakt .form {
        width: 90%;
        margin: auto;
    }
    
    .kontakt .center_vertical_line {
        display: none;
    }
    
    .left {
        width: 90%;
        margin: auto;
    }

    .contact_logo {
        margin: auto;
    }

    .contact_logo img {
        width: 100%;
    }

    .inputBox:nth-child(2) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(3) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(4) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(5) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(6) {
        display: block;
        width: calc(90% + 10px);
    }

    .inputBox:nth-child(7) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(8) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(9) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(10) {
        display: block;
        width: 90%;
    }

    .inputBox:nth-child(11) {
        display: block;
        width: 90%;
    }

    .kontakt .horizontal_line {
        display: block;
        margin: 40px 0;
    }
}