.purvaht {
    width: 100%;
}

.purvaht .section .text {
    border-radius: 30px;
}

.text ul {
    margin: 10px;
    padding: 10px;
}

.text ul li {
    margin: 0;
    padding: 10px;
    line-height: 2em;
}