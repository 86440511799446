.popup, .popup_outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_outer {
    background-color: rgba(0, 0, 0, 0.7);
}

.popup_inner {
    position: absolute;
    padding: 32px;
    max-width: 65%;
    background-color: transparent;
    z-index: 14;
}

.popup_inner img {
    width: 100%;
    max-height: 800px;
}

.popup_inner .close_btn {
    position: absolute;
    font-size: 40px;
    top: 16px;
    right: 16px;
    border: none;
    color: white;
    background: transparent;
    border-radius: 50%;
}

.popup_inner .close_btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 1100px) {
    .popup_inner {
        max-width: 90%;
    }

    .popup_inner img {
        width: 100%;
        max-height: 600px;
    }
}