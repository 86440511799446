.teenused {
    width: 100%;
    display: block;
}

.variant1 button {
    border: 1px solid rgb(21, 78, 21);
    border-radius: 40px;
    background-image: linear-gradient(90deg, rgb(49, 165, 49), rgb(37, 82, 37));
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 150px;
    height: 40px;
    font-weight: bold;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
    transition: 0.5s;
}

.variant1 button:hover {
    background-image: linear-gradient(90deg, rgb(37, 82, 37), rgb(49, 165, 49));
    color: white;
    cursor: pointer;
    transform: scale(1.2);
}