.footer {
    background-image: url("/public/background.png");
	background-size: cover;
    color: white;
    font-weight: bold;
    height: 70px;
    margin: 0;
    position: sticky;
    width: 100%;
    border-top: 2px solid rgb(21, 78, 21);
}

.copyright {
    margin: 0 5%;
    padding-top: 20px;
}