.tehtud_tood {
    width: 100%;
}

.ImageSlider {
    margin: 50px auto;
    width: 60%;
}

.pildid {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.pildid .pildid_kategooria {
    margin: 60px 60px;
    width: 40%;
    display: grid;
    grid-template-columns: repeat(2, 40%);
    justify-content: space-evenly;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
}

.pildid_kategooria h1 {
    grid-column: span 2;
    align-self: center;
    justify-self: center;
    margin: 0;
    padding: 0;
    color: rgb(37, 82, 37);
}

.pildid_kategooria img {
    width: 100%;
    height: 200px;
    box-shadow: 16px 16px 10px rgba(0,0,0,.4);
    border-radius: 10px;
    transition: 0.5s;
}

.pildid_kategooria img:hover {
    opacity: 0.8;
    transform: scale(1.1);
    cursor: pointer;
}

.prev_btn, .next_btn {
    position: absolute;
    font-size: 40px;
    top: 45%;
    border: none;
    color: white;
    background: transparent;
    border-radius: 50%;

}

.prev_btn:hover, .next_btn:hover {
    opacity: 0.8;
    cursor: pointer;
}

.prev_btn {
    left: -20px;
}

.next_btn {
    right: -20px;
}

@media (max-width: 1100px) {
    .ImageSlider {
        width: 90%;
    }

    .pildid .pildid_kategooria {
        margin: 60px auto;
        width: 45%;
        grid-template-columns: repeat(2, 40%);
    }
}

@media (max-width: 550px) {
    .ImageSlider {
        width: 90%;
    }

    .pildid {
        display: grid;
    }

    .pildid .pildid_kategooria {
        margin: 60px auto;
        width: 90%;
        grid-template-columns: repeat(2, 40%);
    }
}