.puistevill {
    width: 100%;
}

.puistevill .section .text {
    border-radius: 30px;
    padding-bottom: 30px;
}

.vill {
    margin-top: 40px;
    padding: 0;
    margin-bottom: 40px;
    position: relative;
}

.vill h1 {
    color: rgb(21, 78, 21);
    text-align: center;
    margin: 0;
}

.villad {
    display: grid;
    grid-template-columns: repeat(5, 17%);
    justify-content: space-evenly;
    grid-row-gap: 60px;
    grid-column-gap: 10px;
    margin-top: 40px;
}

.kast {
    display: grid;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
    background-image: url("/public/background.png");
	background-size: cover;
    border: 2px solid rgb(37, 82, 37);
    height: 500px;
}

.kast h2 {
    color: black;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.3em;
}

.kast p {
    margin: auto;
    padding: 0;
    width: 80%;
    text-align: justify;
    color: white;
    font-size: 0.9em;
}

.kast img {
    width: 100%;
    height: 200px;
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid rgb(37, 82, 37);
}

.kast button {
    margin: auto;
    border: 1px solid rgb(21, 78, 21);
    border-radius: 40px;
    background-image: linear-gradient(90deg, rgb(49, 165, 49), rgb(37, 82, 37));
    width: 170px;
    height: 45px;
    font-weight: bold;
    font-size: 0.8em;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
    transition: 0.5s;
}

.kast button:hover {
    color: white;
    cursor: pointer;
    font-size: 0.9em;
}

.kast a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.variant1 {
    margin-top: 0;
    padding-top: 60px;
}

@media (max-width: 1300px) {
    .villad {
        grid-template-columns: repeat(3, 30%);
    }
}

@media (max-width: 750px) {
    .villad {
        grid-template-columns: repeat(2, 40%);
    }
}

@media (max-width: 550px) {
    .villad {
        grid-template-columns: repeat(1, 60%);
    }

    .kast h2 {
        font-size: 1.2em;
    }

    .kast p {
        font-size: 0.85em;
    }
}