.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    height: 400px;
  }
  
  .react-slideshow-container {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    position:relative;
  }
  
  .react-slideshow-container .nav {
    z-index:10;
    position:absolute;
    cursor:pointer;
  }
  
  .react-slideshow-container .nav:first-of-type {
    left:0;
  }
  
  .react-slideshow-container .nav:last-of-type {
    right:0;
  }
  
  .react-slideshow-container .default-nav {
    height:50px;
    background: transparent;
    width:50px;
    border:0;
    text-align:center;
    color:#fff;
    border-radius:50%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
  }
  
  .react-slideshow-container .default-nav:hover,
  .react-slideshow-container .default-nav:focus {
    color:#666;
    outline:0;
  }
  
  .react-slideshow-container .default-nav.disabled:hover {
    cursor:not-allowed;
  }
  
  .react-slideshow-container .default-nav:first-of-type {
    margin-right:-30px;
    border-right:0;
    border-top:0;
  }
  
  .react-slideshow-container .default-nav:last-of-type {
    margin-left:-30px;
  }
  
  .react-slideshow-container+ul.indicators {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    margin-top:-20px;
  }
  
  .react-slideshow-container+ul.indicators li {
    display:inline-block;
    position:relative;
    width:7px;
    height:7px;
    padding:5px;
    margin:0;
  }
  
  .react-slideshow-container+ul.indicators .each-slideshow-indicator {
    border:0;
    opacity:.6;
    cursor:pointer;
    background:transparent;
    color:transparent;
  }
  
  .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    position:absolute;
    top:0;
    left:0;
    width:7px;
    height:7px;
    border-radius:50%;
    content:'';
    background:white;
    text-align:center;
  }
  
  .react-slideshow-container+ul.indicators .each-slideshow-indicator:hover,
  .react-slideshow-container+ul.indicators .each-slideshow-indicator.active {
    opacity:1;
    outline:0;
  }
  
  .react-slideshow-fadezoom-wrapper {
    width:100%;
    overflow:hidden;
  }
  
  .react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
  }
  
  .react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap>div {
    position:relative;
    opacity:0;
  }
  
  .react-slideshow-wrapper .react-slideshow-fade-images-wrap>div[aria-hidden='true'] {
    display:none;
  }
  
  .react-slideshow-wrapper.slide {
    width:100%;
    overflow:hidden;
    box-shadow: 5px 5px 10px rgba(0,0,0,.25), -5px 0px 10px rgba(0,0,0,.25);
  }
  
  .react-slideshow-wrapper .images-wrap {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
  }
  
  .react-slideshow-wrapper .images-wrap>div[aria-hidden='true'] {
    display:none;
  }