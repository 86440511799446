@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/* Brauseri laius, pikkus, teksti suurus */
.App {
    height: 100%;
    width: 100%;
    font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 1em;
}

/* Navigatsiooniriba */
.header {
    height: 90px;
    max-width: 100vw;
    position: sticky;
    display: flex;
    top: 0;
    justify-content: space-between;
	align-items: center;
	background-image: url("/public/background.png");
	background-size: cover;
	border-bottom: 2px solid rgb(21, 78, 21);
    z-index: 12;
}

.header .logo {
    width: 300px;
    margin-left: 5%;
}

.header .navlinks {
    margin-right: 5%;
}

.navlinks ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navlinks ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    max-width: 130px;
    height: 30px;
    padding: 10px 20px;
    text-align: center;
}

.special::after {
	content: "";
	position: absolute;
	top: -19px;
	left: 50%;
	width: 0%;
	height: 2px;
	background: black;
	transition: 0.4s;
}

.special:hover::after {
	left: 0;
	width: 100%;
}

.special:hover .navdropdown {
    display: inline-block;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    width: fit-content;
    top: 82px;
	left: 0;
	right: 0;
    box-shadow: 2px 2px 6px rgba(0,0,0,.25), -2px 0px 6px rgba(0,0,0,.25);
	z-index: 999;
}

.navdropdown::before {
	content: "";
	position: absolute;
	top: -1rem;
	left: 3.6rem;
	border: 0.5rem solid transparent;
	border-bottom-color: white;
}

.navdropdown li {
    border-top: 2px solid rgb(21, 78, 21);
    font-size: 0.85em;
}

.navlink {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1em;
}

.navlink2 {
    color: black;
    text-transform: none;
    text-decoration: none;
    font-weight: normal;
    font-size: 1em;
}

.navlink2:hover {
    color: #307e27;
	text-decoration: underline;
}

.navlink:hover {
    color: black;
}

.navlinks .navdropdown {
    display: none;
}

.header .mobile_dropdown {
    display: none;
}

/* Navigatsioonipilt ja tekst/veateade */
.nav_photo {
	background-image: url("/public/vill.jpg");
	background-size: cover;
	height: 300px;
	border-bottom: 2px solid green;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nav_photo h1 {
	font-size: 4em;
	font-weight: bold;
	background: -webkit-linear-gradient(rgb(78, 253, 78), rgb(37, 82, 37));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    background-clip: text;
	cursor: default;
}

.error {
	padding: 0;
	margin: 0;
	text-align: center;
	color: black;
    height: calc(100vh - 487px);
}

/* Horisontaalne roheline joon */
.horizontal_line {
    width: 100%;
    height: 2px;
    background: rgb(37, 82, 37);
}

.section {
    margin: 30px auto;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.section .text {
    position: relative;
    display: block;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-left: 8%;
    padding-right: 30px;
    padding-top: 30px;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
}

.text .symbol {
    position: absolute;
    left: 15px;
    top: 15px;
}

.section .image {
    z-index: 1;
}

.section .image img {
    max-width: 350px;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 4px 4px 10px rgba(0,0,0,.25), -4px 0px 10px rgba(0,0,0,.25);
}

.text h1, .text h2, .text h3 {
    margin: 0;
    padding: 0;
    color: rgb(21, 78, 21);
}

.text p {
    margin: 0;
    padding: 20px 20px 0 0;
    color: black;
    line-height: 2em;
    text-align: justify;
    font-size: 1em;
}

.text button {
    margin-top: 20px;
    float: right;
}

.eelised {
    display: flex;
    width: 75%;
    margin: 60px auto;
    justify-content: space-evenly;
}

.eelised .list {
    width: 45%;
}

.list h1 {
    display: block;
    color: rgb(21, 78, 21);
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
}

.list .list_row {
    margin-top: 50px;
    display: flex;
    column-gap: 10px;
}

.list_row .list_element {
    text-align: center;
    width: 150px;
}

.eelised .videobox {
    width: 45%;
}

.videobox video {
    width: 100%;
    border-radius: 30px;
}

/* Kõik, mille max laius on kuni 1100px */
@media (max-width: 1100px) {
    .header .logo {
        width: 250px;
    }

    .header .navlinks {
        display: none;
    }

    .header .mobile_dropdown {
        position: absolute;
        display: block;
        right: 0;
        margin-right: 5%;
        cursor: pointer;
    }

    .mobile_dropdown .nav_bars {
        color: white;
    }

    .header .mobile_navlinks {
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
    }

    .mobile_navlinks ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-image: url("/public/background.png");
	    background-size: cover;
    }

    .mobile_navlinks ul li {
        padding: 15px 0;
        border-bottom: 2px solid rgb(21, 78, 21);
        text-align: center;
    }

    .mobile_navlinks .mobile_navdropdown {
        padding-top: 10px;
    }

    .mobile_navlinks .mobile_navdropdown li {
        border: none;
    }

    .mobile_navlinks .plus {
        color: white;
        margin-left: 10px;
        position: absolute;
    }

    .nav_photo {
        height: 150px;
        background-size: 100% 100%;
    }

    .nav_photo h1 {
        font-size: 2.5em;
        text-align: center;
    }

    .error {
        height: calc(100vh - 337px);
    }

    /* Tekst üleval, pilt all */
    .section {
        margin: 60px 0;
        display: block;
        width: 95%;
    }

    .section .image img {
        display: block;
        margin: 20px auto;
        width: 100%;
        border-radius: 30px;
    }
    
    .section .text {
        margin: auto;
        width: 80%;
        border-radius: 30px;
        padding-bottom: 30px;
    }
    
    .text h1 {
        text-align: center;
    }

    .eelised {
        display: block;
        width: 90%;
        margin: 30px auto;
    }

    .eelised .list {
        width: 95%;
        margin-top: 30px;
    }
    
    .list .list_row {
        margin-top: 30px;
    }
    
    .list_row .list_element {
        text-align: center;
        font-size: 0.8em;
    }

    .eelised .videobox {
        width: 95%;
        margin: auto;
    }
}