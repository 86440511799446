.avaleht {
    width: 100%;
    display: block;
}

/* Teenuste kujundus */
.teenused_avaleht {
    width: 100%;
    margin: 60px auto;
    text-align: center;
    background-image: url("/public/background.png");
	background-size: cover;
    border-top: 2px solid rgb(37, 82, 37);
    border-bottom: 2px solid rgb(37, 82, 37);
}

.teenused_avaleht h1 {
    color: white;
    margin: 30px auto;
    grid-area: teenused;
}

.teenused_avaleht .valikud {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.valikud .valik {
    width: 45%;
    display: block;
}

.valik h2 {
    color: white;
}

.valik p {
    width: 60%;
    margin: auto;
    color: white;
    font-size: 1.1em;
}

.valik video {
    width: 75%;
    border-radius: 50px;
    box-shadow: 16px 16px 10px rgba(0,0,0,.4), -16px 0px 10px rgba(0,0,0,.4);
    transition: 0.5s;
    margin: 10px 0 30px 0;
}

.valik video:hover  {
    transform: scale(1.1);
    opacity: 0.8;
}

.valik img:hover {
    cursor: pointer;
    box-shadow: 16px 16px 10px rgba(0,0,0,.6), -16px 0px 10px rgba(0,0,0,.6);
}

.ImageSlider {
    margin: 50px auto;
    width: 60%;
}

.ImageSlider h1 {
    color: rgb(21, 78, 21);
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 1100px) {
    .teenused_avaleht .valikud {
        display: block;
    }

    .valikud .valik {
        width: 100%;
        display: block;
        padding-bottom: 30px;
    }

    .valik p {
        width: 70%;
        margin: auto;
        color: white;
    }

    .valik video {
        width: 80%;
    }

    .ImageSlider {
        width: 90%;
    }
}